import React from "react"
import {useInfiniteQuery} from "@tanstack/react-query"
import {Wallet} from "@phosphor-icons/react"
import classNames from "classnames"

import "features/offer/OfferLeaderboardTable.css"
import Button from "components/Button/Button"
import LoadingSpinner from "components/LoadingSpinner/LoadingSpinner"
import network from "utils/network"

const TableHeader = () => (
  <div
    key="table-header"
    className="offer-leaderboard-table__header">
    <div className={classNames(
      "offer-leaderboard-table__rank-col",
      "offer-leaderboard-table__header-col",
    )}>
      Rank
    </div>
    <div className={classNames(
      "offer-leaderboard-table__account-col",
      "offer-leaderboard-table__header-col",
    )}>
      Account
    </div>
    <div className={classNames(
      "offer-leaderboard-table__points-col",
      "offer-leaderboard-table__header-col",
    )}>
      Points
    </div>
  </div>
)

const TableRows = ({rows}) => (
  <>
    {rows.map(({rank, wallets, points, isUser, avatarLayers, walletCount, dropWallet}, idx) => (
      <div
        key={`row-${idx}`}
        className={classNames(
          "offer-leaderboard-table__row",
          {
            "offer-leaderboard-table__row--even": idx%2==0,
            "offer-leaderboard-table__row--hl": isUser,
          },
        )}>
        <div className={classNames(
          "offer-leaderboard-table__rank-col",
          "offer-leaderboard-table__rank",
        )}>{rank}</div>
        <div className={classNames(
          "offer-leaderboard-table__account-col",
          "offer-leaderboard-table__account",
        )}>
          <div className="offer-leaderboard-table__avatar-container">
            {avatarLayers.map((imgUrl, idx) => (
              <img
                key={`layer-${idx}`}
                className="offer-leaderboard-table__avatar-layer"
                src={imgUrl} />
            ))}
          </div>
          <div className="offer-leaderboard-table__wallet">[{(wallets?.[0] || dropWallet)}]</div>
          {(walletCount || wallets.length) > 1 && (
            <>
              <div className={classNames(
                "offer-leaderboard-table__extra-wallets",
                {"offer-leaderboard-table__extra-wallets--hl": isUser},
              )}>
                +{(walletCount || wallets.length) - 1}
              </div>
              <Wallet size={18} className="offer-leaderboard-table__wallet-icon" />
            </>
          )}
        </div>
        <div className={classNames(
          "offer-leaderboard-table__points-col",
          "offer-leaderboard-table__points",
          {"offer-leaderboard-table__points--hl": isUser},
        )}>
          {points}
        </div>
      </div>
    ))}
  </>
)

const offerLeaderboardEntriesRequest = ({pageParam}) =>
  network.get(`/offer/leaderboard/entries?lastKey=${pageParam}`)

// this basically flattens pages and calculates rank for each row
const getRows = data => {
  const rows = (data?.pages || [])
    .flatMap(p => p?.body?.entries)
    .reduce(
      (acc, val) => {
        const newRank = acc.points === val.points ? acc.rank : acc.rank + 1
        return {
          rank: newRank,
          points: val.points,
          result: [...acc.result, {...val, rank: newRank}],
        }
      },
      {rank: 0, points: 0, result: []})

  return rows.result
}

const OfferLeaderboardTable = () => {
  const {
    data,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isPending,
    isError,
  } = useInfiniteQuery({
    queryKey: ["offer", "leaderboard", "entries"],
    queryFn: offerLeaderboardEntriesRequest,
    initialPageParam: "",
    getNextPageParam: (lastPage) => lastPage?.body?.lastKey,
  })

  return (
    <div className="offer-leaderboard-table">
      <TableHeader />
      {isPending ? (
        <div className="offer-leaderboard-table__loading">
          <LoadingSpinner />
        </div>
      ) : isError ? (
        <div className="offer-leaderboard-table__error">
          <p>Oops, something went wrong!</p>
        </div>
      ) : (
        <>
          <TableRows rows={getRows(data)} />
          {hasNextPage && (
            <div className="offer-leaderboard-table__load-more">
              <Button
                onClick={fetchNextPage}
                loading={isFetchingNextPage}
                disabled={!hasNextPage || isFetchingNextPage}
                isSecondary>
                {isFetchingNextPage ? "Loading more..." : "Load more"}
              </Button>
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default OfferLeaderboardTable
